import React from "react";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Button, Modal, Typography } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  minWidth: 300,
  maxWidth: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  "&:focus": {
    outline: "none",
  },
};

export default function QRIVRBookingPopup({ Data, confirmModal, cancelModal }) {
  return (
    <Modal open={true}>
      <Box sx={style}>
        <Box
          sx={{
            backgroundColor: "",
            paddingTop: "10px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            display: "flex",
            justifyContent: "center",
          }}
          id="modal-modal-title"
        >
          <Box
            sx={{
              backgroundColor:
                Data.type == "Confirmation"
                  ? "#E77B18"
                  : Data.type == "BOOKED"
                  ? "#41BA8F"
                  : "#BC123E",
              padding: "10px",
              borderRadius: "50px",
              width: "30px",
              height: "30px",
              position: "relative",
              top: 20
            }}
          >
            {Data.type == "Confirmation" ? (
              <SettingsIcon sx={{ fill: "white", fontSize: "30px" }} />
            ) : Data.type == "BOOKED" ? (
              <DoneIcon sx={{ fill: "white", fontSize: "30px" }} />
            ) : (
              <DoDisturbIcon sx={{ fill: "white", fontSize: "30px" }} />
            )}
          </Box>
        </Box>
        <Box sx={{ padding: "20px 30px 30px", textAlign: "center" }}>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontWeight: "bold", whiteSpace: 'pre-wrap', fontFamily: "Poppins" }}
            dangerouslySetInnerHTML={{ __html: Data.message }}
          />
        </Box>
        <Box mb={2}>
          {Data.type == "Confirmation" ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
                paddingBottom: "15px",
              }}
            >
              <Button
                onClick={() => cancelModal()}
                sx={{
                  backgroundColor: "#D13C3C",
                  color: "white",
                  minWidth: "95px",
                  padding: "2px",
                  borderRadius: "20px",
                  "&:hover": {
                    background: "#D13C3C",
                  },
                }}
              >
                no
              </Button>
              <Button
                onClick={() => confirmModal(Data)}
                sx={{
                  backgroundColor: "#41BA8F",
                  color: "white",
                  minWidth: "95px",
                  padding: "2px",
                  borderRadius: "20px",
                  "&:hover": {
                    background: "#41BA8F",
                  },
                }}
              >
                yes
              </Button>
            </Box>
          ) : (
            ["Error_MSG", "ALREADY_BOOKED"].includes(Data.type) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => cancelModal()}
                  sx={{
                    backgroundColor: "#41BA8F",
                    color: "#fff",
                    minWidth: "95px",
                    padding: "2px",
                    borderRadius: "20px",
                    "&:hover": {
                      background: "#41BA8F",
                    },
                  }}
                >
                  Ok
                </Button>
              </Box>
            )
          )}
        </Box>
      </Box>
    </Modal>
  );
}
