// import { apiConfig } from "../config/Config.js";
// import { SC_USER_ID, SC_USER_SESSION_ID } from "../constant/StorageConstant.js";
import BASE_URL from "../Configuration/APIConfig.js";
import { apiHttpStatus, apiHdrDefValue } from "../Constant/ApiConstant.js";
import axios from "axios";

export let ApiService = {
  jsonToQueryString(json) {
    return (
      "?" +
      Object.keys(json)
        .map(function (key) {
          return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
        })
        .join("&")              
    );
  },

  async callGET(apiUrl, BodyData, reqParams) {
    var result =
      '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    try {
      
      let user_name = localStorage.getItem("user_name") || "ADMIN";
      var queryParams = this.jsonToQueryString(reqParams);
      var apiFullEndPoint = BASE_URL + apiUrl + queryParams;
      let resultAPI = await axios.get(apiFullEndPoint, {
        headers: {
          "content-type": apiHdrDefValue.FORM_URLENCODED,
          "authorization": "2024202420242024",
          "app_name": "app",
          "user_name": user_name
        },
      });

      if (resultAPI.status === apiHttpStatus.SC_200) {
        result =
          '{"status": "SUCCESS", "data": ' +
          JSON.stringify(resultAPI.data) +
          "}";
      } else {
        result = '{"status": "FAILED", "error" : "Invalid Credentials"}';
      }
    } catch (err) {
      if (err.response) {
        var eStatusCode = err.response.status;
        var ePayloadTemp = JSON.stringify(err.response.data);
        var ePayload = JSON.parse(ePayloadTemp);

        if (eStatusCode === apiHttpStatus.SC_412) {
          // alert(ePayload.messages[0].message);
          result =
            '{"status": "FAILED", "status_code":'+ eStatusCode +', "error" : ' +
            JSON.stringify(ePayload.messages) +
            "}";
        } else if (eStatusCode >= apiHttpStatus.SC_400) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "status_code":'+ eStatusCode +', "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode >= apiHttpStatus.SC_403) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode >= apiHttpStatus.SC_404) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        }
        else if (eStatusCode >= apiHttpStatus.SC_424) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        }
      } else if (err.request) {
        console.log("Dashboard Network Error:", err);
        result = '{"status": "FAILED", "error" : "Network Error"}';
      } else {
        console.log("Dashboard Client Error:", err);
        result = '{"status": "FAILED", "error" : "Request Error"}';
      }
    }
    // console.log("GET service: ", result);
    return result;
  },

  async callPOST(apiUrl, reqPayload) {
    var result =
      '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';

    try {

      if (reqPayload === "undefined" || reqPayload === "") {
        reqPayload = {};
      }

      if (typeof reqPayload !== "object") {
        reqPayload = {};
      }

      var apiFullEndPoint = BASE_URL + apiUrl;

      let user_name = localStorage.getItem("user_name") || "ADMIN";
      let resultAPI = await axios.post(apiFullEndPoint, reqPayload, {
        headers: {
          "content-type": apiHdrDefValue.APPLICATION_JSON,
          "authorization": "2024202420242024",
          "app_name": "app",
          "user_name": user_name
        },
      });

      if (resultAPI.status === apiHttpStatus.SC_200) {
        result =
          '{"status": "SUCCESS", "data": ' +
          JSON.stringify(resultAPI.data) +
          "}";
      } else {
        result = '{"status": "FAILED", "error" : "Invalid Credentials"}';
      }
    } catch (err) {
      if (err.response) {
        var eStatusCode = err.response.status;
        var ePayloadTemp = JSON.stringify(err.response.data);
        var ePayload = JSON.parse(ePayloadTemp);

        if (eStatusCode === apiHttpStatus.SC_412) {

          result =
            '{"status": "FAILED", "error" : ' +
            JSON.stringify(ePayload.messages) +
            "}";
        } else if (eStatusCode === apiHttpStatus.SC_440) {
          window.location.href = "/login";

        } else if (eStatusCode >= apiHttpStatus.SC_400) {

          result =
            '{"status": "FAILED", "status_code":'+ eStatusCode +', "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode >= apiHttpStatus.SC_403) {

          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        } 
        else if (eStatusCode == apiHttpStatus.SC_404) {
          result =
            '{"status": "FAILED", "status_code":'+ eStatusCode +', "error" : ' + JSON.stringify(ePayload) + "}";
         }
          else if (eStatusCode >= apiHttpStatus.SC_424) {

          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        }
      } else if (err.request) {
        result = '{"status": "FAILED", "error" : "Network Error"}';
      } else {
        result = '{"status": "FAILED", "error" : "Request Error"}';
      }
    }
    console.log("POST service: ", result);
    return result;
  },
  async callPUT( apiUrl, reqPayload) {
    var result =
      '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';

    try {
      if (reqPayload === "undefined" || reqPayload === "") {
        reqPayload = {};
      }
      if (typeof reqPayload !== "object") {
        reqPayload = {};
      }

      let user_name = localStorage.getItem("user_name") || "ADMIN";
      var apiFullEndPoint = BASE_URL + apiUrl;
      let resultAPI = await axios.put(apiFullEndPoint, reqPayload, {
        headers: {
          "content-type": apiHdrDefValue.APPLICATION_JSON,
          "authorization": "2024202420242024",
          "app_name": "app",
          "user_name": user_name
        }
      });
      console.log(resultAPI)
      if (resultAPI.status === apiHttpStatus.SC_200) {
        result =
          '{"status": "SUCCESS", "data": ' +
          JSON.stringify(resultAPI.data) +
          "}";
      } else {
        result = '{"status": "FAILED", "error" : "Invalid Credentials"}';
      }
    } catch (err) {
      if (err.response) {
        var eStatusCode = err.response.status;
        var ePayloadTemp = JSON.stringify(err.response.data);
        var ePayload = JSON.parse(ePayloadTemp);

        if (eStatusCode === apiHttpStatus.SC_412) {
          // alert(ePayload.messages[0].message);
          result =
            '{"status": "FAILED", "error" : ' +
            JSON.stringify(ePayload.messages) +
            "}";
        } else if (eStatusCode >= apiHttpStatus.SC_400) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        } 
        else if (eStatusCode >= apiHttpStatus.SC_403) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        }
        else if (eStatusCode >= apiHttpStatus.SC_404) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
         } 
         else if (eStatusCode >= apiHttpStatus.SC_424) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        }
      } else if (err.request) {
        result = '{"status": "FAILED", "error" : "Network Error"}';
      } else {
        result = '{"status": "FAILED", "error" : "Request Error"}';
      }
    }
    console.log("PUT service: ", result);
    return result;
  },
  async callDELETE( apiUrl, reqParams) {
    var result =
      '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';

    try {
      if (reqParams === "undefined" || reqParams === "") {
        reqParams = {};
      }

      if (typeof reqParams !== "object") {
        reqParams = {};
      }
      // var queryParams = this.jsonToQueryString(reqParams);
      let user_name = localStorage.getItem("user_name") || "ADMIN";
      var apiFullEndPoint = BASE_URL + apiUrl;
      let resultAPI = await axios.delete(apiFullEndPoint, {
        headers: {
          "content-type": apiHdrDefValue.FORM_URLENCODED,
          "authorization": "2024202420242024",
          "app_name": "app",
          "user_name": user_name
        },
      });

      if (resultAPI.status === apiHttpStatus.SC_200) {
       result = '{"status": "SUCCESS", "data": ' + JSON.stringify(resultAPI.data) +"}";
      } else {
        result = '{"status": "FAILED", "error" : "Invalid Credentials"}';
      }
    } catch (err) {
      if (err.response) {
        var eStatusCode = err.response.status;
        var ePayloadTemp = JSON.stringify(err.response.data);
        var ePayload = JSON.parse(ePayloadTemp);

        if (eStatusCode === apiHttpStatus.SC_412) {
          // alert(ePayload.messages[0].message);
          result =
            '{"status": "FAILED", "error" : ' +
            JSON.stringify(ePayload.messages) +
            "}";
        } else if (eStatusCode >= apiHttpStatus.SC_400) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode >= apiHttpStatus.SC_403) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        } else if (eStatusCode >= apiHttpStatus.SC_424) {
          // alert(ePayload.message);
          result =
            '{"status": "FAILED", "error" : ' + JSON.stringify(ePayload) + "}";
        }
      } else if (err.request) {
        console.log("Dashboard Network Error:", err);
        result = '{"status": "FAILED", "error" : "Network Error"}';
      } else {
        console.log("Dashboard Client Error:", err);
        result = '{"status": "FAILED", "error" : "Request Error"}';
      }
    }
    return result;
  },
};

export default ApiService;
