import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Table,
  TablePagination,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  Menu,
  MenuItem,
  Button,
  Divider,
  Avatar,
  TextField,
  Breadcrumbs,
  Modal,
  Paper,
  IconButton,
  Typography,
  Stack,
  Pagination,
  Tooltip,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// ------ custom import files --------
import "./manageappointment.css";
import TableCardData from "../../../Constant/Dashboard.json";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import RunningWithErrorsOutlinedIcon from "@mui/icons-material/RunningWithErrorsOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CancelAppointment from "../../../Popup/CancelAppointment";
import BioDetails from "../../ReuseComponents/Bio/BioDetails";
import TimeAdjustment from "../../../Popup/TimeAdjustment";
import EditAppointment from "../../../Popup/EditAppointment";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../ReuseComponents/BreadCrumbs/BreadCrumbs";
import BookAppointment from "../../../Popup/BookAppointment";
import DeleteAppointment from "../../../Popup/DeletePopup";
import Controller from "../../../Controller/ApiController";
import UtilDateTime from "../../../Constant/utillDateTime";
import { useLocation } from "react-router-dom";
import deleteicon from "../../../Assets/images/DeletePopup/deleteImage.png";
import { Alert } from "../../ReuseComponents/Alert/Alertmessage";
import editDisabledIcon from "../../../Assets/images/AppoinmentIcon/editDisableicon.svg";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
// import { appointmentReferesh } from "../../Assets/Redux/features/Appointment/appointmentReferesh";
import { useSelector } from "react-redux";
import Header from "../../ReuseComponents/Header/Header";
import delayIcon from "../../../Assets/icons/timeDelay.svg";
import BlockIcon from "../../../Assets/images/AppoinmentIcon/blockImage.svg";
import BlockAppointments from "./blockAppointments";
import { manageAppointmentReferesh } from "../../Assets/Redux/features/Appointment/manageappointmnetRef";
import BulkMove from "../../../Popup/BulkMove";
import bulkMoveIcon from "../../../Assets/icons/bulkMoveIcon.svg"
import SwapVertIcon from '@mui/icons-material/SwapVert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EastIcon from "@mui/icons-material/East";
import PaymentInformation from "../../../Popup/paymentInformation";

export default function ManageAppointment() {
  const dispatch = useDispatch();

  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [cancelBulkModalOpen, setCancelBulkModalOpen] = useState(false);
  const [timeModalOpen, setTimeModalOpen] = useState(false);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [paymentOpenModal, setPaymentOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [appointmentData, setAppointmentData] = useState({});
  const location = useLocation();
  const [appointmentAddUpdate, setAppointmentAddUpdate] = useState(0);
  const [DeleteUserDetails, SetDeleteUserDetails] = useState();
  const [loaderopen, setloaderOpen] = React.useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [reRenderFunction, setReRenderFunction] = useState(false);
  const [sort, setSort] = useState("DESC");
  const [sortBy, setSortBy] = useState("from_time");
  const userType = localStorage.getItem("user_type");

  const currentDate = new Date();
  // Set it to the start of the day
  currentDate.setHours(0, 0, 0, 0);
  // Convert to ISO string
  const startOfDayISOString = currentDate.toJSON();
  const storedDate = useSelector((state) => state.dateFilter.date);
  const filterData = useSelector((state) => state.filterData.data);
  const userDetails = useSelector((state) => state.userDetails.data);

  const handleLoaderClose = () => {
    setloaderOpen(false);
  };
  const handleLoaderOpen = () => {
    setloaderOpen(true);
  };

  async function fetchGetAPI(currentPage, perPage) {
    handleLoaderOpen();
    let docteruid = localStorage.getItem("DoctorUid");
    
    let data = {
      "filter.doctor_uid": docteruid,
      page: currentPage + 1,
      count: perPage,
      sort_by: sortBy,
      sort: sort,
      // "filter.appointment_date": storedDate || startOfDayISOString,
      
    };    
    if (filterData.from_date) {
      data = {
        ...data,
        "filter.from_time": filterData.from_date.toISOString()
      };
    } 
    if (filterData.to_date) {
      data = {
        ...data,
        "filter.to_time": filterData.to_date.toISOString()
      };
    }

    if (userDetails.user_uid && ["SCAN", "DOCTOR"].includes(userDetails.user_type)) {
      data = { ...data, "filter.user_uid": userDetails.user_uid };
    } else if(filterData.user_uid.length > 0) {
      data = { ...data, "filter.user_uid": filterData.user_uid.join(",") };
    } else if(filterData.maped_user_uid.length > 0) {
      data = { ...data, "filter.user_uid": filterData.maped_user_uid.join(",") };
    } else if(userDetails.user_uid && !["SCAN", "DOCTOR"].includes(userDetails.user_type)) {
      data = { ...data, "filter.user_uid": userDetails.user_uid };
    }

    console.log(userDetails, data, "dataibidata");
    // const query = { "filter.appointment_date": "2024-10-14T12:33:55.599Z" };
    let response = await Controller.ApiController(
      "GET",
      "/appointment",
      "",
      data
    );
    handleLoaderClose();
    if (response.type == "success") {
      console.log(response, "ManageAppoinmentListing");
      setUserData(response);
    } else {      
      setUserData({});
    }
  }
  
  useEffect(() => {
    if(reRenderFunction) {
    const currentUrl = new URL(window.location.href);
    let pageNumber = currentUrl.searchParams.get("page");
    let perPage = currentUrl.searchParams.get("perPage");
    pageNumber = parseInt(pageNumber) || 0;
    perPage = parseInt(perPage) || 0;
    if(pageNumber > 0 || perPage > 4) {
      fetchGetAPI(pageNumber, perPage);
      setPage(pageNumber);
      setRowsPerPage(perPage)
    }else {
      startOfThePage();
    }
  } else {
    setReRenderFunction(true);
  }
  }, [count, sortBy, sort]);

  useEffect(() => {
    if(userDetails.filter && filterData.filter) {
      startOfThePage();
    }
  }, [filterData, userDetails]);

  useEffect(() => {
    if(reRenderFunction) {
    setCount((prev) => prev + 1)
    }
  }, [
    appointmentAddUpdate,
    rowsPerPage
  ]);

  useEffect(() => {
    dispatch(manageAppointmentReferesh(updateCount));
  }, []);

  const updateCount = () => {
    setCount((prev) => prev + 1);
  };

  const convertToTitleCase = (inputString) => {
    const words = inputString.toLowerCase().split("_");

    const titleCaseWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    const titleCaseString = titleCaseWords.join(" ");

    return titleCaseString;
  };

  const ClickBreadCrumbs = (event) => {
    event.preventDefault();
  };

  const handleCancelModalOpen = () => {
    setCancelModalOpen(true);
  };

  const handleCancelModalClose = () => {
    setCancelModalOpen(false);
  };

  const handleTimeModalOpen = () => {
    setTimeModalOpen(true);
  };

  const handleTimeModalclose = () => {
    setTimeModalOpen(false);
  };

  const handleBlockModalOpen = () => {
    setBlockModalOpen(true);
  };

  const handleBlockModalclose = () => {
    setBlockModalOpen(false);
  };

  const handleEditModalOpen = () => {
    setEditModalOpen(true);
  };

  const handleEditModalclose = () => {
    setEditModalOpen(false);
  };

  const handleOpenModal = (RowCellData) => {
    if (
      RowCellData.booking_status == "NOT_ARRIVED" ||
      RowCellData.booking_status == "IN_QUE"
    ) {
      setAppointmentData(RowCellData);
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  };

  const handlePaymentOpenModal = (RowCellData) => {
    setAppointmentData(RowCellData);
    setPaymentOpenModal(true);
  };
  
  const handlePaymentClose = () => {
    setPaymentOpenModal(false);
    setAppointmentData({});
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setAppointmentData({});
  };

  const handleDeleteCloseModal = () => {
    setDeleteModal(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuIndex, setMenuIndex] = useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setMenuIndex(index);
  };
  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };
  const handleClose = () => {
    setDeleteModal(false);
  };
  const handleChangePage = () => {};

  const handleOpen = (deleteDetails) => {
    if (
      deleteDetails.booking_status == "NOT_ARRIVED" ||
      deleteDetails.booking_status == "IN_QUE"
    ) {
      SetDeleteUserDetails(deleteDetails);
      setDeleteModal(true);
    } else {
      setDeleteModal(false);
    }
  };

  const DeleteUser = async () => {
    if (DeleteUserDetails) {
      let res = await Controller.ApiController(
        "DELETE",
        "/appointment/" + DeleteUserDetails.appointment_uid
      );
      if (res.type == "success") {
        Alert("success", " Deleted successfully");
        setDeleteModal(false);
        setPage(0);
        addURLQueryParam(0, rowsPerPage);
        fetchGetAPI(0, rowsPerPage);
      }
    }
  };

  const startOfThePage = () => {
    addURLQueryParam( 0, 15);
    fetchGetAPI(0, 15);
    setPage(0);
    setRowsPerPage(15);
  }

  const handleChange = (event, value) => {
    setPage(value);
    addURLQueryParam(value, rowsPerPage);
    fetchGetAPI(value, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));    
    addURLQueryParam(0, parseInt(event.target.value));
    setPage(0);
    fetchGetAPI(0, event.target.value);
  };

  const addURLQueryParam = (page, rowsPerPage) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", page);
    url.searchParams.set("perPage", rowsPerPage);
    window.history.replaceState({}, '', url);
  };

  const convertconsultationType = (inputString) => {
    return inputString == "NORMAL"
      ? "New"
      : inputString == "EMERGENCY"
      ? "EMG"
      : inputString == "REVIEW_APPOINTMENT"
      ? "Review"
      : "";
  };

  const handleBulkMoveOpen = () => {
    setCancelBulkModalOpen(true);
  };

  const handleBulkMoveClose = () => {
    setCancelBulkModalOpen(false);
  };

  
  const UpdateStatus = async function (value, data, action) {
    handleLoaderOpen();
    
    let res = {};

    let updateData = { booking_status: value }
      res = await Controller.ApiController(
        "PUT",
        `/appointment/${data.appointment_uid}`,
        "",
        updateData
      );
    // alert(JSON.stringify(datas));
    // alert(data?.appointment_uid);

    handleLoaderClose();
    if (res) {
      console.log(res, "dataListApi");

      if (res.type === "success") {
        handleClose();
        setCount((current) => current + 1);
        // setAppointmentAddUpdate(prev => alert(prev));
        handleLoaderClose();
        Alert("success", "Appointment updated successfully");
      } else {
        handleLoaderClose();
        Alert("error", res.error.message);
      }
    }
  };

  const handleSort = () => {
    setSort((prevSort) => (prevSort === "ASC" ? "DESC" : "ASC"));
  };

  const validateConfirmationColor = (data) => {
    let value = "#999999";
    if(data.is_triggered) {
      if(data.is_confirmed) {
        value = "#41BA8F"
      } else if(data.is_cancelled) {
        value = "#BC123E"
      } else if(data.is_rescheduled) {
        value = "#BC123E"
      } else {
        value = "#E77B18"
      }
    }
    
    return value;
  }

  const validateConfirmationTooltip = (data) => {
    let value = "";
    
    if(data.is_triggered) {
      if(data.is_confirmed) {
        value = "Confirmed"
      } else if(data.is_cancelled) {
        value = "Client Cancelled"
      } else if(data.is_rescheduled) {
        value = "Client Rescheduled"
      } else {
        value = "Waiting for Confirmation"
      }
    }else{
      value = "Pending Confirmation";
    }

    return value;
  }

  return (
    <>
      <Grid container spacing={0} display={"flex"} alignItems={"center"}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
          <Header />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginTop: { xs: "0px", sm: "0px" }, marginBottom: "25px" }} >
          <BreadCrumbs
            crumbs={["Dashboard", "Manage Appointments"]}
            paths={["/dashboard", "/manageappointments"]}
            selected={(crumb) => console.log("hello", crumb)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box>
            <BioDetails />
          </Box>
          <Box sx={{ padding: "15px 0px", display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none", }, }} >
            <h3>Manage Appointments</h3>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
          sx={{
            textAlign: { md: "end", sx: "start" },
            display: { md: "flex", sx: "", xs: "" },
            justifyContent: { md: "end", sx: "start", xs: "start" },
          }}
        >
          <Button
            className="flx"
            sx={{ color: "#000", whiteSpace: "nowrap",  }}
            onClick={() => handleBulkMoveOpen()}
            
          >
            {/* <Avatar
              sx={{
                width: "25px",
                height: "25px",
                backgroundColor: "#BC123E",
              }}
            >
              <bulkMoveIcon sx={{ color: "#fff", fontSize: "18px" }} />
            </Avatar> */}
            <img src={bulkMoveIcon} alt="bulkMoveIcon" />

            <p className="center-image-txt">Bulk Move</p>
          </Button>
          <Button
            className="flx"
            sx={{ color: "#000", whiteSpace: "nowrap" }}
            onClick={handleCancelModalOpen}
          >
            <Avatar
              sx={{
                width: "25px",
                height: "25px",
                backgroundColor: "#BC123E",
              }}
            >
              <DoNotDisturbAltIcon sx={{ color: "#fff", fontSize: "18px" }} />
            </Avatar>

            <p className="center-image-txt">Cancel Appointments</p>
          </Button>
          <Button
            className="flx"
            sx={{ color: "#000", whiteSpace: "nowrap" }}
            onClick={handleTimeModalOpen}
          >
            <Avatar
              sx={{
                width: "25px",
                height: "25px",
                backgroundColor: "#2C73EB",
              }}
            >
              <RunningWithErrorsOutlinedIcon
                sx={{ color: "#fff", fontSize: "18px" }}
              />
            </Avatar>

            <p className="center-image-txt">Consultation Delay</p>
          </Button>
          {userType == "SCAN" ? ("") : (
          <Button
            className="flx"
            sx={{ color: "#000", whiteSpace: "nowrap" }}
            onClick={handleBlockModalOpen}
          >
            <Avatar
              sx={{
                width: "25px",
                height: "25px",
                backgroundColor: "#111D7D",
              }}
            >
              {/* <RunningWithErrorsOutlinedIcon
                sx={{ color: "#fff", fontSize: "18px" }}
              /> */}
              <img src={BlockIcon} alt="blockImage" />
            </Avatar>

            <p className="center-image-txt">Block Appointments</p>
          </Button>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={0} sx={{padding: "5px 0px 0px 0px", marginBottom: { xs: "50px", md: "0px" }, }}>
        <Grid item sm={12} md={12} lg={12}>
          <Paper sx={{ width: { sm: "100%", xs: "380px" },}}>
            <TableContainer sx={{overflowY:"hidden !important" }}>
              <Table
                // stickyHeader
                sx={{ minWidth: { sm: "800px", xs: "300px" } }}
                aria-label="customized table"
              >
                <TableHead sx={{overflowY:"hidden !important" }}>
                  <TableRow
                    sx={{ whiteSpace: { sm: "normal", xs: "nowrap" } }}
                    key={"appointmentTableheader"}
                  >
                    {TableCardData.tableRowHead2.map((RowHeadData, index) => (
                      <TableCell
                        style={{
                          color: "#676666",
                          fontSize: "14px",
                          fontWeight: 600,
                          padding: "5px",
                          // width: RowHeadData.width,
                          backgroundColor: "#EEF0FA",
                          whiteSpace: "nowrap",
                          textAlign: RowHeadData.TblHead == "Actions" ? "center" : ""
                        }}
                        key={index}
                        onClick={RowHeadData.key === "filter" ? handleSort : null}
                        sx={{
                          cursor: RowHeadData.key === "filter" ? "pointer" : "default",
                        }}
                      >
                        {RowHeadData.TblHead}
                        {RowHeadData.key == "filter" && (
                          <span style={{ marginLeft: 1, position:"relative", top:3 }}>
                          {sort === "ASC" ? <SwapVertIcon sx={{ fontSize: 16 }} /> : <SwapVertIcon sx={{ fontSize: 16 }} />}
                        </span>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody sx={{overflowY:"hidden !important" }}>
                  {userData?.data?.length > 0 ? (
                    userData?.data?.map((RowCellData, index) => (
                      <TableRow key={index}>
                        <TableCell className="RowCellDatamanage">
                          {index + 1 + (userData.current_page - 1) * rowsPerPage}
                        </TableCell>
                        <TableCell className="RowCellDatamanage" sx={{position: "relative"}}>
                          {UtilDateTime.formatYearMonthAndDayAsFullString(
                            RowCellData?.from_time || "-"
                          )}
                          {RowCellData.consultation_type == "REVIEW_APPOINTMENT" && RowCellData?.is_triggered && <Tooltip title={validateConfirmationTooltip(RowCellData)} placement="top"
                          slotProps={{
                            tooltip: {
                              sx: {
                                backgroundColor: "#f5f5f9",
                                color: "rgba(0, 0, 0, 0.87)",
                                border: "1px solid #dadde9",
                                "& .MuiTooltip-arrow": {
                                  color: "#f5f5f9",
                                },
                              },
                            },
                          }}
                          >        
                            <CheckCircleIcon sx={{fontSize: "20px", color: validateConfirmationColor(RowCellData), position: "absolute", marginLeft: "5px"}} />
                          </Tooltip>}
                        </TableCell>
                        <TableCell className="RowCellDatamanage">
                          {UtilDateTime.formatTimeAsHHMMTT(
                            RowCellData?.from_time || "-"
                          )}
                          {RowCellData?.is_delay && (
                            <Tooltip
                              placement="right"
                              title={`Initial booking time is ${UtilDateTime.formatTimeAsHHMMTT(
                                RowCellData?.start_time
                              )}`}
                            >
                              <span style={{ cursor: "pointer" }}>
                                <img
                                  src={delayIcon}
                                  style={{
                                    color: "#2C73EB",
                                    marginLeft: "10px",
                                  }}
                                />
                              </span>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell className="RowCellDatamanage">
                          {convertconsultationType(
                            RowCellData?.consultation_type
                          ) || "-"}
                        </TableCell>
                        <TableCell className="RowCellDatamanage">
                          {RowCellData?.token || "-"}
                        </TableCell>
                        <TableCell className="RowCellDatamanage">
                          {RowCellData.patient_name || "-"}
                        </TableCell>
                        <TableCell className="RowCellDatamanage">
                          {RowCellData.mobile_number || "-"}
                        </TableCell>
                        <TableCell className="RowCellDatamanage">
                          {RowCellData?.manage_user?.full_name || "-"}
                        </TableCell>

                        <TableCell
                          className="RowCellDatamanage"
                          
                        >
                          {RowCellData?.booking_status &&
                          ![
                            "CANCELLED",
                            "CHECK_OUT",
                            "BULK_CANCELLED"
                          ].includes(RowCellData?.booking_status) ? (
                            <Box>
                              <Button
                                id="basic-button"
                                aria-controls={open ? "basic-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={(e) => handleClick(e, index)}
                                sx={{ padding: "0px" }}
                                aria-readonly
                              >
                                <Box
                                  sx={{
                                    color:
                                      RowCellData.booking_status ==
                                      "NOT_ARRIVED"
                                        ? "#BC123E"
                                        : RowCellData.booking_status == "IN_QUE"
                                        ? "#DA7314"
                                        : RowCellData.booking_status ==
                                          "CHECK_OUT"
                                        ? "#22B175"
                                        : RowCellData.booking_status ==
                                          "CHECK_IN"
                                        ? "#22B175"
                                        : RowCellData.booking_status ==
                                          "CANCELLED"
                                        ? "#ff0000"
                                        : "black",
                                    textAlign: "left",
                                    width: "105px",
                                    textTransform: "none",
                                  }}
                                >
                                  {RowCellData.booking_status &&
                                    convertToTitleCase(
                                      RowCellData?.booking_status
                                    )}
                                </Box>
                                <Box
                                  sx={{
                                    position: "relative",
                                    top: "3px",
                                    color: "black",
                                  }}
                                >
                                  <KeyboardArrowDownIcon />
                                </Box>
                              </Button>
                              {menuIndex == index && (
                                <Menu
                                  sx={{ boxShadow: "none" }}
                                  id="basic-menu"
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleCloseAnchor}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                  }}
                                >
                                  <MenuItem
                                      sx={{ color: "#ff0000",fontSize:"14px" }}
                                      onClick={() =>
                                        UpdateStatus("CANCELLED", RowCellData, "STATUS")
                                      }
                                    >
                                      Cancel
                                    </MenuItem>
                                </Menu>
                              )}
                            </Box>
                          ) : RowCellData?.booking_status ? (
                            <div sx={{}}>
                              <p
                                style={{
                                  fontSize: "14px",
                                  padding: "0px",
                                  height: "0px",
                                  marginBottom: "0px",
                                  marginTop: "-7px",
                                  border: "none",
                                  color:
                                    RowCellData.booking_status == "NOT_ARRIVED"
                                      ? "#BC123E"
                                      : RowCellData.booking_status == "IN_QUE"
                                      ? "#DA7314"
                                      : RowCellData.booking_status ==
                                        "CHECK_OUT"
                                      ? "#22B175"
                                      : RowCellData.booking_status == "CHECK_IN"
                                      ? "#22B175"
                                      : RowCellData.booking_status ==
                                        "CANCELLED"
                                      ? "#ff0000"
                                      : RowCellData.booking_status ==
                                        "BULK_CANCELLED"
                                      ? "#ff0000"
                                      : "black",
                                }}
                              >
                                {convertToTitleCase(
                                  RowCellData?.booking_status
                                )}
                              </p>
                            </div>
                          ) : (
                            <p sx={{ textAlign: "left" }}>
                              {convertToTitleCase(RowCellData?.slot_status)}
                            </p>
                          )}
                        </TableCell>
                        <TableCell className="RowCellDatamanage" sx={{textAlign: "center", width: "120px"}}>
                          {RowCellData.booking_status ? (
                            <Box >
                              {userDetails.user_type == "SUPER_ADMIN" && <Tooltip
                                title="Payment Details"
                                arrow
                                slotProps={{
                                  tooltip: {
                                    sx: {
                                      backgroundColor: "#f5f5f9",
                                      color: "rgba(0, 0, 0, 0.87)",
                                      border: "1px solid #dadde9",
                                      "& .MuiTooltip-arrow": {
                                        color: "#f5f5f9",
                                      },
                                    },
                                  },
                                }}
                              >
                                <span>
                                  
                                <IconButton
                                    sx={{
                                      backgroundColor: "#41BA8F",
                                      height: "25px",
                                      width: "25px",
                                      margin: "4px 5px",
                                      "&:hover": { backgroundColor: "#41BA8F" },
                                      "&.Mui-disabled": {backgroundColor: "#999999"}
                                    }}
                                    disabled={!RowCellData.is_payment || !["CHECK_IN", "CHECK_OUT"].includes(
                                      RowCellData.booking_status
                                    )}
                                    onClick={() => handlePaymentOpenModal(RowCellData)}
                                  >
                                    <EastIcon
                                      sx={{ color:  "#fff", fontSize: "17px" }}
                                    />
                                  </IconButton>
                                </span>
                              </Tooltip>}
                              <Tooltip
                                title="Edit"
                                arrow
                                slotProps={{
                                  tooltip: {
                                    sx: {
                                      backgroundColor: "#f5f5f9",
                                      color: "rgba(0, 0, 0, 0.87)",
                                      border: "1px solid #dadde9",
                                      "& .MuiTooltip-arrow": {
                                        color: "#f5f5f9",
                                      },
                                    },
                                  },
                                }}
                              >
                                <Button
                                  size="small"
                                  sx={{ minWidth: "auto" }}
                                  onClick={() => handleOpenModal(RowCellData)}
                                  // style={{ position: "relative", right: 4 }}
                                  // disabled={}
                                >
                                  { (
                                    <Avatar
                                      sx={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                                        width: "25px",
                                        height: "25px",
                                        backgroundColor:
                                          RowCellData.booking_status ==
                                            "NOT_ARRIVED" ||
                                          RowCellData.booking_status == "IN_QUE"
                                            ? "#243665"
                                            : "#999999",
                                      }}
                                    >
                                      <EditIcon
                                        sx={{ color: "#fff", fontSize: "18px" }}
                                      />
                                    </Avatar>
                                  )}
                                </Button>
                              </Tooltip>
                              <Tooltip
                                title="Delete"
                                arrow
                                slotProps={{
                                  tooltip: {
                                    sx: {
                                      backgroundColor: "#f5f5f9",
                                      color: "rgba(0, 0, 0, 0.87)",
                                      border: "1px solid #dadde9",
                                      "& .MuiTooltip-arrow": {
                                        color: "#f5f5f9",
                                      },
                                    },
                                  },
                                }}
                              >
                                <Button
                                  size="small"
                                  sx={{
                                    minWidth: "auto",
                                    // marginLeft:
                                    //   RowCellData.consultation_type ==
                                    //   "EMERGENCY"
                                    //     ? "25px"
                                    //     : "",
                                  }}
                                  onClick={() => handleOpen(RowCellData)}
                                  disabled={
                                    RowCellData.booking_status == "IN_QUE"
                                      ? true
                                      : false
                                  }
                                >
                                  <Avatar
                                    sx={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                                      width: "25px",
                                      height: "25px",
                                      backgroundColor:
                                        RowCellData.booking_status ==
                                        "NOT_ARRIVED"
                                          ? "#BC123E"
                                          : "#999999",
                                    }}
                                  >
                                    <DeleteOutlinedIcon
                                      sx={{ color: "#fff", fontSize: "18px" }}
                                    />
                                  </Avatar>
                                </Button>
                              </Tooltip>
                            </Box>
                          ) : (
                            <Button
                              sx={{
                                background: "#243665",
                                color: "#FFFFFF",
                                padding: "0px 15px",
                                borderRadius: "20px",
                              }}
                            >
                              select
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableCell
                      sx={{ textAlign: { md: "center", xs: "start" } }}
                      colSpan={10}
                    >
                      No Appointment Found
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          {userData.total_records > 0 && (
            <TablePagination
              rowsPerPageOptions={[15, 25, 50, 100]}
              component="div"
              count={userData.total_records}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Grid>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loaderopen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={deleteModal}
      >
        <Backdrop
          open={deleteModal}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Paper sx={{ width: "350px", borderRadius: "10px" }}>
            <Grid sx={{ borderRadius: "15px" }} container>
              <Grid
                item
                xs={12}
                sx={{ backgroundColor: "#EEF0FA", borderRadius: "10px" }}
              >
                <Box
                  sx={{
                    height: "70px",
                    backgroundColor: "#FFD7D7",
                    borderRadius: "10px 10px 0px 0px",
                  }}
                >
                  <IconButton
                    sx={{
                      marginTop: "35px",
                      marginLeft: "142px",
                      backgroundColor: "#D13C3C",
                      height: "54px",
                      width: "55px",
                      "&:hover": { backgroundColor: "#D13C3C" },
                      cursor: "default",
                    }}
                  >
                    <img src={deleteicon} alt="deleteicon" />
                  </IconButton>
                </Box>
              </Grid>
              <hr />
              <Grid item md={12} xs={12} sm={12} sx={{ padding: "0% 5% 5%" }}>
                <Box>
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        mt={2}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          fontWeight: 600,
                          fontSize: "16px",
                        }}
                      >
                        Are you sure you want to
                        <br /> delete the appointment?
                      </Typography>
                    </Grid>
                  </Grid>

                  <Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "center" }}
                      mt={2}
                    >
                      <Button
                        sx={{
                          mr: 2,
                          backgroundColor: "#000000",
                          color: "#FFFFFF",
                          height: "25px",
                          "&:hover": { background: "#000000" },
                          width: "95px",
                          fontSize: "13px",
                          fontWeight: 600,
                          borderRadius: "30px",
                          textTransform: "none",
                        }}
                        onClick={handleClose}
                      >
                        No
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: "#41BA8F",
                          color: "#FFFFFF",
                          height: "25px",
                          "&:hover": { background: "#41BA8F" },
                          width: "95px",
                          fontSize: "13px",
                          fontWeight: 600,
                          borderRadius: "30px",
                          textTransform: "none",
                        }}
                        onClick={DeleteUser}
                      >
                        Yes
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Backdrop>
      </Modal>

      {cancelModalOpen && <CancelAppointment
        open={cancelModalOpen}
        onClose={handleCancelModalClose}
        updateCount={setCount}
      />}
      {timeModalOpen && (
        <TimeAdjustment open={timeModalOpen} onClose={handleTimeModalclose}  />
      )}
      {blockModalOpen && (
        <BlockAppointments
          open={blockModalOpen}
          onClose={handleBlockModalclose}
        />
      )}
      {/* <BookAppointment
        fetchGetAPIData={fetchGetAPI}
        name="EditUser"
        type="editUser"
        open={openModal}
        data={data}
        setAppointmentAddUpdate={setAppointmentAddUpdate}
        onClose={handleCloseModal}
      /> */}
      {openModal && (
        <BookAppointment
          name="EditUser"
          type="editUser"
          open={openModal}
          data={appointmentData}
          fetchGetAPIData={fetchGetAPI}
          updateCount={setCount}
          onClose={handleCloseModal}
        />
      )}
      
            {paymentOpenModal && (
              <PaymentInformation
                open={paymentOpenModal}
                onClose={handlePaymentClose}
                appointmentData={appointmentData}
                updateCount={updateCount}
              />
            )}
    {cancelBulkModalOpen && (
          <BulkMove
          open={cancelBulkModalOpen}
          onClose={handleBulkMoveClose}
          // fetchGetAPIData={fetchGetAPI}
        />
    )}


      {/* <EditAppointment
      open ={editModalOpen}
      onClose={handleEditModalclose}
    /> */}
    </>
  );
}
