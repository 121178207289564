import React from 'react'
import { Routes, Route } from "react-router-dom";
import Loginpage from '../Components/pages/Login/Loginpage';
import ForgotPassword from '../Components/pages/Forgotpassword/ForgotPassword';
import SignupDoctor from '../Components/pages/SingUp/SignupDoctor';
import SignupOtp from '../Components/pages/SingUp/SignupOtp';
import ResetPassword from '../Components/pages/Forgotpassword/ResetPassword';
import VerifyEmail from '../Components/pages/VerifyEmail/VerifyEmail';
import QRIVRBooking from "../Components/pages/QRGenerator/QRIVRBooking";

export default function LoginRoute() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Loginpage />} />
                <Route path="/login" element={<Loginpage />} />
                <Route path="/signup" element={<SignupDoctor />} />
                <Route path="/signupotp" element={<SignupOtp />} />
                <Route path="/forgetpassword" element={<ForgotPassword />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route path='/verify_email' element={<VerifyEmail />} />
                <Route path='/qr-ivr-booking/:doctor_uid' element={<QRIVRBooking />} />
            </Routes>
        </div>
    )
}
