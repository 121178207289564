import React, { useEffect, useState } from "react";
import {
  alpha,
  Box,
  Button,
  Checkbox,
  Chip,
  ClickAwayListener,
  Divider,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Popper,
  styled,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

// ---- custom Files ------
import "./biodetails.css";

// ------ custom icons and images ------
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Calender from "../../../Assets/dashboard/calender.svg";
import Hospital from "../../../Assets/dashboard/hospital.svg";
import Doctor from "../../../Assets/dashboard/doctor.svg";
import { useLocation } from "react-router-dom";
import Controller from "../../../Controller/ApiController";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { BasicSelect, MultipleSelect } from "../Select/BasicSelect";
import { BasicMenuItem } from "../Select/MenuItem";
import {
  DesktopDatePicker,
  LocalizationProvider,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DateRange } from "@mui/icons-material";
import {
  CancelButton,
  ContainedButton,
  OutlinedButton,
  TextButton,
} from "../Button/BasicButton";
import { updateFilterData } from "../../Assets/Redux/features/StoreData/filterData";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import UtilDateTime from "../../../Constant/utillDateTime";
import GeneralSetting from "../../../Popup/generalSetting";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    maxHeight: "220px",
    marginTop: theme.spacing(1),
    width: 130,
    color: "rgb(55, 65, 81)",
    backgroundColor: "#fff",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      backgroundColor: "#fff",
    },
    "& .MuiMenuItem-root": {
      padding: "5px",
      "&:hover": {
        backgroundColor: "#fff",
      },
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
    // ...theme.applyStyles("dark", {
    //   color: theme.palette.grey[300],
    // }),
  },
}));

const shortcutsItems = [
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Next Month",
    getValue: () => {
      const today = dayjs();
      const startOfNextMonth = today.endOf("month").add(1, "day");
      return [startOfNextMonth, startOfNextMonth.endOf("month")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

export default function BioDetails({filterInQue}) {
  const initialDoctorList = [
    { doctor_name: "Aarav" },
    { doctor_name: "Neha" },
    { doctor_name: "Rohan" },
  ];

  const formattedDateTime = (date) => {
    return new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",

      // hour: 'numeric',
      // minute: 'numeric',
      // hour12: true,
    }).format(date);
  };

  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState("");
  const currentPath = useLocation();
  const [selectedDate, setSelectedDate] = useState("");
  const [customDate, setCustomDate] = useState({
    from: dayjs(new Date()).add(2, "day"), //.subtract(1, "day"),
    to: dayjs(new Date()).add(3, "day"),
  });
  const [menuItemValue, setMenuItemValue] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [topWidgetData, setTopWidgetData] = useState();
  const [hospitalData, setHospitalData] = useState({});
  const storedDate = useSelector((state) => state.dateFilter.date);
  const [doctorList, setDoctorList] = useState(initialDoctorList);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const currentDate = dayjs();
  const [users, setUsers] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [customValue, setCustomValue] = useState("");
  const [generalSettingOpen, setGeneralSettingOpen] = useState(false);

  const theme = useTheme();

  // Detect screen size using Material-UI's useMediaQuery
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // for small screens

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getAnchorOrigin = () => {
    if (isSmallScreen) {
      return {
        vertical: "bottom",
        horizontal: "center",
      };
    } else {
      return {
        vertical: "center",
        horizontal: "right",
      };
    }
  };

  const getTransformOrigin = () => {
    if (isSmallScreen) {
      return;
    } else {
      return {
        vertical: "center",
        horizontal: "left",
      };
    }
  };

  const handleMenuClose = () => {
    // Close the menu only if the popover is not open
    if (!popoverAnchor) {
      setAnchorEl(null);
    }
  };

  const handleCustomHover = (event) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
  };

  const handleCustomClick = () => {
    // Keep menu open when "Custom" is clicked
  };

  const handleClickAway = (event) => {
    setAnchorEl(null);
    setPopoverAnchor(null);
  };

  const [customAnchorEl, setCustomAnchorEl] = React.useState(null);
  const [customOpen, setCustomOpen] = React.useState(false);

  const handleCustomHoverIn = (event) => {
    console.log(customAnchorEl, "customAnchorElsrfsgr");
    setCustomAnchorEl(event.currentTarget);
    setCustomOpen(true);
  };

  const handleCustomHoverOut = (event) => {
    setCustomAnchorEl(null);
    setCustomOpen(false);
  };

  const handleClick = (event) => {};
  const handleClose = () => {
    // if(!customAnchorEl) {

    setCustomAnchorEl(null);
    setCustomOpen(false);
    // }
  };

  const FullName = localStorage.getItem("full_name");
  const DoctorName = localStorage.getItem("user_name");
  const HospitalName = localStorage.getItem("hospital_name");

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state?.userDetails?.data || {});
  const filterData = useSelector((state) => state?.filterData?.data || {});

  useEffect(() => {
    async function GetTopWidgetData() {
      var UID = localStorage.getItem("DoctorUid");
      var Data = {
        "filter.doctor_uid": UID,
        "filter.date": storedDate,

      };
      let data = {};
      let response = await Controller.ApiController("GET", `/dashboard/${UID}`);
      if (response.type == "success") {
        setHospitalData(response.data);
      }
      console.log(response, "grgr");
    }
    GetTopWidgetData();
  }, [storedDate]);

  const userRequest = async () => {
    try {

      const queryParams = {
        "filter.user_type": "DOCTOR,SCAN",
        "filter.doctor_uid": userDetails.admin_uid || "",
        "filter.user_uid": userDetails?.user_uid || "",
        "filter.user_uid_type": "MAPED_DOCTOR"

      };

      const response = await Controller.ApiController(
        "GET",
        "/manage_users",
        "",
        queryParams
      );
      console.log(response, "responsemonresponse1");
      if (response.type == "success") {
        console.log(response?.data, "responsemonresponse2"); 
        setUsers(response?.data || []);
        if(!["SCAN", "DOCTOR", "SUPER_ADMIN"].includes(userDetails.user_type)) {
          dispatch(
            updateFilterData({
              ...filterData,
              filter: true
            }))
        } else {
          dispatch(
            updateFilterData({
              ...filterData,
              filter: true
            }))
        }
      }
    } catch (e) {
      console.log("User Request: ", e.message);
    }
  };

  const handleGeneralSetting= () => {
    setGeneralSettingOpen(false);
  }

  useEffect(() => {
    if (userDetails.filter) {
      userRequest();
    }
  }, [userDetails]);

  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  });

  useEffect(() => {
    console.log(topWidgetData, "sss");
  }, [topWidgetData]);

  useEffect(() => {
    if (storedDate) {
      setCurrentDateTime(new Date(storedDate));
    } else {
      setCurrentDateTime(new Date());
    }
    const intervalId = setInterval(() => {
      if (storedDate) {
        setCurrentDateTime(new Date(storedDate));
      } else {
        setCurrentDateTime(new Date());
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [storedDate]);


  const handleTimeChange = (field, time) => {
    setCustomDate({ ...customDate, [field]: time })
    // Reset "To" time if "From" time changes to ensure consistency
    if (field === "from") {
      let toTime = dayjs(customDate.to);
      if(time.isAfter(toTime)) {
        setCustomDate((prev) => ({ ...prev, to: time }));
      }
    }

    if (field === "to") {
      let fromTime = dayjs(customDate.from);
      if(time.isBefore(fromTime)) {
        setCustomDate((prev) => ({ ...prev, from: time }));
      }
    }
  };

  const handlechange = (e) => {
    const { value, name } = e.target;
    let filerValue = typeof value === "string" ? value.split(",") : value;
    filerValue = filerValue.filter((val) => val != "Select");
    setSelectedDoctors(filerValue);

    dispatch(
      updateFilterData({
        ...filterData,
        user_uid: filerValue.map((val) => val.user_uid),
        pageFilter: true
      })
    );
  };

  const handleDatechange = (key) => {
    let value = formattedDateTime(currentDate);
    let filterFrom = currentDate.startOf("date");
    let filterTo = currentDate.endOf("date");
    if (key == "TODAY") {
      value = formattedDateTime(currentDate);
      setAnchorEl(null);
      setPopoverAnchor(null);
    } else if (key == "YESTERDAY") {
      value = formattedDateTime(dayjs(currentDate.subtract(1, "days")));
      filterFrom = dayjs(currentDate.subtract(1, "days")).startOf("date");
      filterTo = dayjs(currentDate.subtract(1, "days")).endOf("date");
      setAnchorEl(null);
      setPopoverAnchor(null);
    } else if (key == "TOMORROW") {
      value = formattedDateTime(dayjs(currentDate.add(1, "days")));
      filterFrom = dayjs(currentDate.add(1, "days")).startOf("date");
      filterTo = dayjs(currentDate.add(1, "days")).endOf("date");
      setAnchorEl(null);
      setPopoverAnchor(null);
    } else if (key == "CUSTOM") {
      value =
        formattedDateTime(dayjs(customDate.from)) +
        " to " +
        formattedDateTime(dayjs(customDate.to));
      filterFrom = dayjs(customDate.from).startOf("date");
      filterTo = dayjs(customDate.to).endOf("date");
      setAnchorEl(null);
      setPopoverAnchor(null);
    } else if (key == "CLEAR") {
      value = "";
      setAnchorEl(null);
      setPopoverAnchor(null);
      filterFrom = filterFrom;
      filterTo = "";
    }
    setSelectedDate(value);
    dispatch(
      updateFilterData({
        ...filterData,
        from_date: filterFrom || "",
        to_date: filterTo || "",
        pageFilter: true
      })
    );
    handleClose();
  };

  
  const disableDatesFrom = (date) => {
    const today = dayjs();
    const tomorrow = today.add(1, "day");
    let disable = false;

    if(date.isAfter(customDate.to)){
      disable = true
    }
    return disable;
  };

  
  const disableDatesTo = (date) => {
    const customFrom = dayjs(customDate.from);
    const beforeOneDay = customFrom.subtract(1, "day");
    let disable = false;
    
    if(date.isBefore(beforeOneDay)){
      disable = true
    }
    return disable;
  };

  return (
    <>
      <Box
        sx={{
          display: { sm: "flex", xs: "" },
          marginTop: { sm: "", xs: "-10px" },
          justifyContent: "space-between",
        }}
      >
        <Box sx={{
          display: { sm: "flex", xs: "" },
          justifyContent: "left",
        }}>
        {!currentPath.pathname.includes("/reports") && 
        <div
          className="flx"
          style={{
            marginBottom: height > width ? "5px" : "0px",
            // maxWidth: "250px",
            whiteSpace: "nowrap",
            marginRight: height > width ? "0px" : "50px",
          }}
        >
          <img
            src={Calender}
            sx={{ height: { sm: "30px", xs: "30px",  } }}
            alt={"Calender.logo"}
          />

          <ClickAwayListener onClickAway={handleClickAway}>
            <div>
              {currentPath.pathname == "/manageappointments" || currentPath.pathname == "/reports" ? (
                <>
                  <OutlinedButton
                    id="demo-customized-button"
                    disableElevation
                    onClick={handleMenuOpen}
                    endIcon={<KeyboardArrowDownIcon />}
                    sx={{
                      fontSize: "14px",
                      ml: 2,
                      height: "30px",
                      textTransform: "none",
                      fontWeight: 400
                      // minWidth: "220px",
                    }}
                  >
                    {selectedDate ? <span style={{fontWeight: 400 }}>{selectedDate}</span> : (
                      <span style={{ color: "#bbb7b7", fontWeight: 400 }}>Select</span>
                    )}
                  </OutlinedButton>
                  {selectedDate && (
                    <Tooltip title="Clear" placement="top">
                      <IconButton onClick={() => handleDatechange("CLEAR")}>
                        <HighlightOffIcon sx={{ color: "#BC123E" }} />
                      </IconButton>
                    </Tooltip>
                    // <CancelButton
                    //   onClick={() => handleDatechange("CLEAR")}
                    // >Clear</CancelButton>
                  )}
                </>
              ) : (
                <Typography
                  className="center-image-txt"
                  sx={{ fontSize: { sm: "14px", xs: "12px" }, ml: 2 }}
                >
                  {currentPath.pathname.includes("/dashboard") ? UtilDateTime.formatMonthDateYear(storedDate) : formattedDateTime(currentDate)}
                </Typography>
              )}
              <StyledMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
              <MenuItem
                onClick={() => handleDatechange("YESTERDAY")}
                sx={{ fontSize: "14px" }}
              >
                Yesterday
              </MenuItem>
                <MenuItem
                  onClick={() => handleDatechange("TODAY")}
                  sx={{ fontSize: "14px" }}
                >
                  Today
                </MenuItem>
                <MenuItem
                  onClick={() => handleDatechange("TOMORROW")}
                  sx={{ fontSize: "14px" }}
                >
                  Tomorrow
                </MenuItem>
                <MenuItem
                  disableRipple
                  onClick={handleCustomHover}
                  sx={{ fontSize: "14px" }}
                >
                  Custom
                </MenuItem>
              </StyledMenu>

              <Popover
                open={Boolean(popoverAnchor)}
                anchorEl={popoverAnchor}
                onClose={handlePopoverClose}
                anchorOrigin={getAnchorOrigin()}
                transformOrigin={getTransformOrigin()}
                disableEnforceFocus
                disableAutoFocus
                disableRestoreFocus
                sx={{
                  "& .MuiPopover-paper": {
                    overflow: "hidden",
                    padding: "0px 10px",
                  },
                }}
              >
                <Box sx={{}}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
                    <DesktopDatePicker
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: {
                          sx: {
                            "& .MuiOutlinedInput-root": {
                              width: "135px",
                              height: "30px",
                              padding: 0,
                              margin: "5px 10px 5px 0px",
                              fontSize: "14px",
                              paddingRight: "0px",
                              "& fieldset": {
                                borderColor: "#C4C4C4",
                              },
                              color: "#000",
                              fontFamily: "Segoe UI",
                              "&:hover fieldset": {
                                borderColor: "#000",
                              },
                              "&.Mui-focused fieldset": {
                                border: "1px solid #C4C4C4",
                              },
                            },
                            ".MuiIconButton-root": {
                              marginRight: "8px",
                              padding: "0px",
                            }
                          },
                          inputProps: { readOnly: true },
                        },
                      }}
                      // shouldDisableDate={disableDatesFrom}
                      value={customDate.from || dayjs(new Date())}
                      onChange={(newValue) => handleTimeChange("from", newValue)}
                    />

                    <DesktopDatePicker
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: {
                          sx: {
                            "& .MuiOutlinedInput-root": {
                              width: "135px",
                              height: "30px",
                              padding: 0,
                              paddingRight: "0px",
                              fontSize: "14px",
                              margin: "5px 10px 5px 0px",
                              "& fieldset": {
                                borderColor: "#C4C4C4",
                              },
                              color: "#000",
                              fontFamily: "Segoe UI",
                              "&:hover fieldset": {
                                borderColor: "#000",
                              },
                              "&.Mui-focused fieldset": {
                                border: "1px solid #C4C4C4",
                              },
                            },
                            ".MuiIconButton-root": {
                              marginRight: "8px",
                              padding: "0px",
                            }
                          },
                          inputProps: { readOnly: true },
                        },
                      }}
                      // shouldDisableDate={disableDatesTo}
                      value={customDate.to || dayjs(new Date())}
                      onChange={(newValue) => handleTimeChange("to", newValue)}
                    />
                  </LocalizationProvider>
                  <ContainedButton
                    onClick={() => handleDatechange("CUSTOM")}
                    sx={{
                      margin: "5px 0px 5px 0px",}}
                  >
                    Apply
                  </ContainedButton>
                </Box>
              </Popover>
            </div>
          </ClickAwayListener>
        </div>}

        <div
          className="flx"
          style={{
            maxWidth: "350px",
            marginRight: "20px",
            marginBottom: height > width ? "5px" : "0px",
          }}
        >
          <img
            src={Hospital}
            sx={{ height: { sm: "30px", xs: "30px" } }}
            alt={"Calender.logo"}
          />
          <Typography
            className="center-image-txt"
            sx={{ fontSize: { sm: "14px", xs: "12px" }, ml: 2 }}
          >
            {hospitalData.hospital_or_clinic_name || "--"}
          </Typography>
        </div>

        {(!currentPath.pathname.includes("addusers") && !currentPath.pathname.includes("/reports") && !currentPath.pathname.includes("Viewusers") && !currentPath.pathname.includes("Editusers")  && !currentPath.pathname.includes("/view-settings"))
        && !currentPath.pathname.includes("/Profile")
        ? (
        <div
        className="flx"
        style={{
          marginLeft: height > width ? "0px" : "30px",
          maxWidth: "350px",
        }}
      >
        <img
          src={Doctor}
          sx={{ height: { sm: "30px", xs: "30px" } }}
          alt={"Calender.logo"}
        />
        {(userDetails.user_type == "DOCTOR" || userDetails.user_type == "SCAN") ? (
          <Typography
            className="center-image-txt"
            sx={{ fontSize: { sm: "14px", xs: "12px" }, ml: 2 }}
          >
            {userDetails.user_full_name || "--"}
          </Typography>
        ) : (
          <MultipleSelect
            sx={{ width: "200px", ml: 2, mr: 1 }}
            value={selectedDoctors.length > 0 ? selectedDoctors : ["Select"]}
            onChange={handlechange}
            typeOfValue={selectedDoctors.length > 0 && "OBJECT"}
            typeOfKey="full_name"
          >
            <MenuItem value="Select" style={{ display: "none" }}>
              Select
            </MenuItem>
            {Array.isArray(users) && users.length > 0 ? users.map((val, i) => (
              ((filterInQue == "OPEN" && val.user_type == "DOCTOR") || filterInQue != "OPEN")  && <BasicMenuItem key={i} value={val}>
                <ListItemText
                  primary={val.full_name}
                  sx={{
                    "& .MuiListItemText-primary": {
                      fontSize: { sm: "14px", xs: "12px" },
                    },
                  }}
                />
                <Checkbox
                  sx={{ padding: 0, marginLeft: "10px", '&.Mui-checked': { color: "#243665", } }}
                  checked={selectedDoctors.includes(val)}
                  size="small"
                />
              </BasicMenuItem>
            )) : <BasicMenuItem disabled>
                No Doctors Available
              </BasicMenuItem>}
          </MultipleSelect>
        )}
      </div>
        ) : ("")
        }
        </Box>
        {userDetails.user_type == "SUPER_ADMIN" && currentPath.pathname.includes("/all-settings") && <Box sx={{ marginTop: "12px", alignContent: "center"}}>
          <ContainedButton onClick={() => setGeneralSettingOpen(true)}
            sx={{
              // mt: 3,
              mb: 2,
              mr: 2,
              backgroundColor: "#243665",
              color: "#FFFFFF",
              borderRadius: "50px",
              height: "30px",
              "&:hover": { background: "#243665" },
              width: "145px",
              
            }}
          >
            General Setting
          </ContainedButton>
        </Box>}
      </Box>
      {generalSettingOpen && <GeneralSetting open={generalSettingOpen} onClose={handleGeneralSetting} />}
    </>
  );
}
